<template>
  <div style="position: relative">
    <a-button type="primary" @click="onExport" class="btnExport">导出</a-button>
    <a-card :tab-list="tabList" :active-tab-key="currentTab" @tabChange="changeTab">
      <a-row :gutter="24">
        <a-col :span="10">
          <a-form-model-item :wrapper-col="{ span: 24 }">
            <a-radio-group v-model="radioValue" button-style="solid" @change="changeRadio" style="width: 100%;">
              <a-radio-button value="0" style="width: 25%;">今日</a-radio-button>
              <a-radio-button value="1" style="width: 25%;">昨天</a-radio-button>
              <a-radio-button value="-6" style="width: 25%;">近7天</a-radio-button>
              <a-radio-button value="-29" style="width: 25%;">近30天</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="自定义时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-range-picker v-model="searchForm.dateRange" @change="changeDate" style="width: 100%;" />
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="分类" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
            <a-select v-model="searchForm.category" allowClear style="width: 100%;" @change="changeCategory">
              <a-select-option v-for="item in categoryItems" :key="item.id" :value="item.id">{{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-descriptions :column="{ xxl: 4, xl: 4, lg: 4, md: 2, sm: 2, xs: 1 }" style="text-align: center;">
        <a-descriptions-item label="销售次数"><span class="total">{{totalCount}}</span></a-descriptions-item>
        <a-descriptions-item label="销售数量"><span class="total">{{totalQuantity}}</span></a-descriptions-item>
        <a-descriptions-item label="销售金额"><span class="total">{{totalAmount}}</span></a-descriptions-item>
      </a-descriptions>

      <div>
        <flow-pane v-show="currentTab === 'flow'" :items="items" :loading="loading" :pagination="pagination" @tableChange="tableChange" />
        <goods-pane v-show="currentTab === 'goods'" :items="items" :loading="loading" :pagination="pagination" @tableChange="tableChange" />
        <user-pane v-show="currentTab === 'user'" :items="items" :loading="loading" :pagination="pagination" @tableChange="tableChange" />
      </div>
      <div style="text-align: center; margin-top: 12px;">
        <a-spin :spinning="loading && searchForm.page > 1" />
      </div>
    </a-card>
  </div>
</template>

<script>
  import { exportExcel1 } from '@/utils/excel'
  import { saleExport } from '@/api/sale'
  import { salesReportStatistics, salesReportDetialsList, salesReportByGoodsList, salesReportByUserList } from '@/api/report'
  import { goodsClassificationOption } from '@/api/option'
  import NP from 'number-precision'
  import moment from 'moment'

  export default {
    name: 'SalesReport',
    components: {
      FlowPane: () => import('./flowPane'),
      GoodsPane: () => import('./goodsPane'),
      UserPane: () => import('./userPane')
    },
    data() {
      return {
        listExport: [],
        listExport1: [],
        listExport2: [],
        NP,
        currentTab: 'flow',
        radioValue: '0',
        tabList: [
          {
            key: 'flow',
            tab: '销售明细',
          },
          {
            key: 'goods',
            tab: '按产品汇总',
          },
          {
            key: 'user',
            tab: '按客户汇总',
          }
        ],
        searchForm: {
          dateRange: [moment().startOf('day'), moment().startOf('day')],
          category: null,
          page: 1,
          page_size: 16
        },
        loading: true,
        categoryItems: [],
        total: {
          quantity: 0,
          cost: 0,
          amount: 0,
        },
        items: [],
        pagination: { current: 1, total: 0, pageSize: 16 },
      };
    },
    computed: {
      totalCount() {
        return this.total.total_count ? this.total.total_count : 0
      },
      totalQuantity() {
        return this.total.total_quantity ? this.total.total_quantity : 0
      },
      totalAmount() {
        return this.total.total_amount ? NP.round(this.total.total_amount, 2) : 0
      },
    },
    methods: {
      getExportList() {
        let form = {
          start_date: this.searchForm.dateRange.length > 0 ? this.searchForm.dateRange[0].format('YYYY-MM-DD') : null,
          end_date: this.searchForm.dateRange.length > 0 ? this.searchForm.dateRange[1].format('YYYY-MM-DD') : null,
          category: this.searchForm.category,
          page: 1,
          page_size: 2000,
        };
        if (form.end_date) {
          form.end_date = moment(form.end_date).add(1, 'days').format('YYYY-MM-DD');
        }
        if (this.currentTab === 'flow') {
          salesReportDetialsList(form).then(resp => {
            this.listExport = resp.results;
          })
        }else if(this.currentTab === 'goods'){
          salesReportByGoodsList(form).then(resp => {
            this.listExport1 = resp.results;
          })
        }else {
          salesReportByUserList(form).then(resp => {
            this.listExport2 = resp.data;
          })
        }
      },
      getCurrentDate() {
        let today = new Date();
        let year = today.getFullYear();
        let month = (today.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，需要+1，并且补0到两位数
        let day = today.getDate().toString().padStart(2, '0'); // 补0到两位数
        let formattedDate = `${year}${month}${day}`; // 拼接年月日
        return formattedDate;
      },
      onExport(){
        import("../../../utils/xlsx.js").then((excel) => {
          //tHeader 为导出后的表头
          let tHeader = [], filterVal = [], list = []
          if (this.currentTab === 'flow') {
            tHeader = ["产品编号", "产品名称", "规格", "分类名称", "单位", "销售单价", "总金额", "销售单号", "仓库", "供应商", "创建人", "创建时间"];
            filterVal = ["goods_number", "goods_name", "goods_spec", "category_name", "unit_name", "sales_price", "total_amount", "sales_order_number", "warehouse_name", "supplier_name", "creator_name", "create_time"];
            list = this.listExport
          } else if(this.currentTab === 'goods'){
            tHeader = ["产品编号", "产品名称", "规格", "单位", "销售总数量", "销售总金额", "最低销售价", "平均销售价", "最高销售价"];
            filterVal = ["goods_number", "goods_name", "goods_spec", "unit_name", "total_sales_quantity", "total_sales_amount", "min_sales_price", "avg_sales_price", "max_sales_price"];
            list = this.listExport1
          } else {
            tHeader = ["客户编号", "客户名称", "最大值", "最小值", "平均值", "总数量", "销售总金额"];
            filterVal = ["client_number", "client_name", "max_sales_price", "min_sales_price", "avg_sales_price", "total_sales_quantity", "total_sales_amount"];
            list = this.listExport2
          }
          const data = this.formatJson(filterVal, list);
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename:`销售报表${this.getCurrentDate()}`,
            autoWidth: true,
            bookType: "xlsx",
          });
        });
      },
      formatJson(filterVal, jsonData) {
        return jsonData.map((v) =>
                filterVal.map((j) => {
                  if (j === "timestamp") {
                    return parseTime(v[j]);
                  } else {
                    return v[j];
                  }
                })
        );
      },
      initailize() {
        if (this.$route.query.type) {
          this.currentTab = this.$route.query.type;
        } else {
          this.$router.replace({ query: { type: this.currentTab } })
        }
        this.list();
        goodsClassificationOption({ page_size: 99999 }).then(resp => {
          this.categoryItems = resp.results;
        });
      },
      list() {
        let form = {
          start_date: this.searchForm.dateRange.length > 0 ? this.searchForm.dateRange[0].format('YYYY-MM-DD') : null,
          end_date: this.searchForm.dateRange.length > 0 ? this.searchForm.dateRange[1].format('YYYY-MM-DD') : null,
          category: this.searchForm.category,
          page: this.searchForm.page,
          page_size: this.searchForm.page_size,
        };
        if (form.end_date) {
          form.end_date = moment(form.end_date).add(1, 'days').format('YYYY-MM-DD');
        }

        this.loading = true;
        salesReportStatistics(form).then(resp => {
          this.total = resp;
        })
        if (this.currentTab === 'flow') {
          salesReportDetialsList(form).then(resp => {
            this.items = resp.results;
            this.pagination.total = resp.count;
          }).finally(() => {
            this.loading = false;
          });
        } else if(this.currentTab === 'goods'){
          salesReportByGoodsList(form).then(resp => {
            this.items = resp.results;
            this.pagination.total = resp.count;
          }).finally(() => {
            this.loading = false;
          });
        } else {
          salesReportByUserList(form).then(resp => {
            this.items = resp.data;
            this.pagination.total = resp.count;
          }).finally(() => {
            this.loading = false;
          });
        }
        this.getExportList()
      },
      tableChange(pagination, filters, sorter) {
        this.searchForm.page = pagination.current;
        this.pagination.current = pagination.current;
        this.searchForm.ordering = `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`;
        this.list();
      },
      search() {
        this.searchForm.page = 1;
        this.pagination.current = 1;
        this.list();
      },
      changeTab(key) {
        this.currentTab = key;
        this.$router.replace({ query: { type: this.currentTab } })
        this.resetForm();
        this.search();
      },
      changeRadio(evt) {
        let value = evt.target.value;
        if (value < 0) {
          this.searchForm.dateRange = [moment().startOf('day').add(value, 'day'), moment().startOf('day')]
        } else {
          this.searchForm.dateRange = [moment().startOf('day').subtract(value, 'day'), moment().startOf('day').subtract(value, 'day'),]
        }
        this.search();
      },
      changeDate() {
        this.radioValue = null;
        this.search();
      },
      changeCategory() {
        this.search();
      },
      resetForm() {
        this.radioValue = '0';
        this.searchForm = {
          dateRange: [moment().startOf('day'), moment().startOf('day')],
          category: null,
          page: 1,
          page_size: 16
        };
      },
    },
    mounted() {
      this.initailize();
    },
  }
</script>

<style scoped>
  .total {
    color: #a94442;
  }
  .btnExport{
    position: absolute;
    right: 40px;
    top: 20px;
    z-index: 99;
  }
</style>
